.nav-links {
  margin: 1rem auto;

  li {
    transition: all 0.5s ease;
  }

  li.preload {
    opacity: 0;
    transform: translateX(150px);
  }

  a {
    position: relative;
    text-decoration: none;
    color: whitesmoke;

    &::after {
      content: "";
      display: block;
      width: 0;
      height: 2px;
      position: absolute;
      left: 50%;
      bottom: -6px;
      background: whitesmoke;
      transition: width 0.3s ease 0s, left 0.3s ease 0s;
    }

    &:hover::after {
      width: 100%;
      left: 0;
    }
  }
}
