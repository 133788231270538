.header-subtitle {
  text-align: center;

  h2 {
    display: inline-block;
    width: auto;
    margin: 0;
    font-size: 1.6rem;
    transition: all 0.5s ease;

    @media screen and (min-width: 800px) {
      font-size: 2.2rem;
    }
  }

  #laptop,
  #rocket {
    position: relative;
    width: 4.8rem;
    cursor: pointer;
  }

  .fire {
    &::before {
      content: "🔥";
      position: absolute;
      bottom: 0.6rem;
      left: 1.2rem;
      opacity: 0.6;
      animation: wiggle 0.8s infinite;
    }
  }

  .launch {
    transform: translate(500px, -500px);
  }

  .clickme {
    &::after {
      content: "(click me)";
      position: absolute;
      bottom: -1.5rem;
      left: 0;
      font-size: 1rem;
    }
  }

  .hide {
    display: none;
  }
}
