#back-to-top {
  position: fixed;
  bottom: 0.5rem;
  right: 0.5rem;
  font-size: 4rem;
  color: #292929;
  opacity: 0.5;
  transition: all 0.3s ease-in-out;

  &:hover {
    opacity: 1;
  }

  svg {
    width: 44px;
    border: 1px solid #292929;
    border-radius: 7px;
    background: linear-gradient(45deg, lightgrey, grey, lightgrey);
    box-shadow: 1px 1px 2px #292929;
  }
}
