body {
  background: linear-gradient(45deg, ivory, lightslategrey, ivory);
  font-family: "Noto Sans JP", sans-serif;

  #root {
    font-size: 1.6rem;
  }

  h1,
  h2,
  h3 {
    width: 100%;
    text-align: center;
  }

  h1 {
    font-size: 4rem;
    transition: all 0.5s ease;
  }

  h1.preload {
    opacity: 0;
    transform: translateY(-50px);
  }

  h2 {
    margin: 0 auto 3%;
    font-size: 2.4rem;
  }

  h3 {
    margin: 1rem auto;
    font-size: 1.6rem;
  }

  ul {
    list-style: none;
    text-align: center;

    li {
      display: inline-block;
      margin: 2%;
    }
  }

  .section-description {
    width: 90%;
    margin: 5% auto 0;
  }
}

section {
  margin: 5% auto 7%;
}

main {
  transition: all 0.5s ease;
}

main.preload {
  opacity: 0;
  transform: translateY(150px);
}

header,
footer {
  background: linear-gradient(45deg, lightslategrey, darkslategrey);
  color: whitesmoke;
}

footer {
  padding: 2rem;

  @media screen and (min-width: 800px) {
    text-align: center;
  }
}
