#projects,
#build-week {
  @media screen and (min-width: 800px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
}

.project-card {
  position: relative;
  margin: 5%;
  padding: 5%;
  box-shadow: 3px 3px 6px #292929;
  background: ivory;
  font-family: monospace;

  @media screen and (min-width: 800px) {
    width: 30%;
    margin: 1.5% 0;
    padding: 1%;
  }

  h4 {
    margin: 1% auto 3%;
    font-size: 2rem;
    font-weight: 100;
    text-align: center;
  }

  .img-container {
    display: flex;
    justify-content: center;

    img {
      border: 2px solid #292929;
      max-height: 200px;
      min-height: 200px;
    }
  }

  .exit-icon,
  .info-icon {
    position: absolute;
    top: 10px;
    z-index: 10;
    width: 20px;
    text-align: center;
  }

  .exit-icon {
    right: 10px;
    color: ivory;
  }

  .info-icon {
    left: 10px;
    &:hover {
      color: whitesmoke;
    }

    &:hover ~ div.description-overlay {
      height: 100%;
      opacity: 0.9;
    }
  }

  .description-overlay {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    overflow: hidden;
    padding: 2rem;
    background: #292929;
    color: whitesmoke;
    opacity: 0;
    transition: all 0.5s ease;

    .tech-stack {
      width: 100%;
      margin: 1.6rem auto;
      font-size: 1.4rem;
      text-align: center;
    }

    .feature-list {
      width: 80%;
      margin: 0 auto;
      text-align: left;
      list-style: circle;

      li {
        display: list-item;
      }
    }
  }

  .link-btns {
    display: flex;
    justify-content: space-between;
    margin: 7% 0 0;

    a {
      display: inline-block;
      width: 35%;
      margin: auto;
      border: 1px solid #292929;
      border-radius: 7px;
      padding: 1%;
      background: linear-gradient(
        45deg,
        lightslategrey,
        darkslategrey,
        lightslategrey
      );
      color: whitesmoke;
      text-align: center;
      text-decoration: none;
      transition: all 0.3s ease;

      &:hover {
        background: linear-gradient(45deg, lightgrey, grey, lightgrey);
        color: #292929;
      }
    }
  }
}
